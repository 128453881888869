<template>
  <div class="mobilePage">
    <div class="headerBox" @click="getApkInfo(true)"></div>
    <div class="content">
      <van-tabs
        v-model="tabActive"
        title-active-color="#ffff"
        title-inactive-color="#ffffff"
        animated
        :lazy-render="false"
        class="vantTabs"
      >
        <van-tab
          :title="item.label"
          :name="item.label"
          v-for="item in tabs"
          :key="item.value"
        >
          <div class="main" @click="getApkInfo()">
            <div class="content1" v-if="item.value == 'hot'"></div>
            <div class="content2" v-if="item.value == 'darkweb'"></div>
            <div class="content3" v-if="item.value == 'cartoon'"></div>
            <div class="content4" v-if="item.value == 'nation'"></div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="floatingBox">
        <div class="btn1" @click="toOfficial"></div>
        <div class="btn2" @click="toBusiness"></div>
      </div>
      <div class="bottomBox" @click="getApkInfo(true)">
        <div class="bottomImg"></div>
      </div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
      tabActive: "",
      tabs: [
        {
          label: "国产推荐",
          value: "hot",
        },
        {
          label: "成人动漫",
          value: "darkweb",
        },
        {
          label: "暗网禁地",
          value: "cartoon",
        },
        {
          label: "欧美日韩",
          value: "nation",
        },
      ],
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    // alert(ua)
    console.log(ua);
    console.log(isSafari);
    // console.log(ua.indexOf('safari'), "------");
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
      // console.log(this.showTip, '-------------')
      // alert('请使用Safari浏览器进行下载')
    }
    // const referrer = sessionStorage.getItem("referrer");
    // const dc = sessionStorage.getItem("dc");
    // if (referrer && dc) {
    //   this.currUrl = referrer + "user.html?dc=" + dc;
    // } else {
    //   if (referrer) {
    //     this.currUrl = referrer + "user.html";
    //   } else {
    //     this.currUrl = window.location.href;
    //   }
    // }
  },
  methods: {
    // 剪贴板
    doCopy() {
      let url = window.location.href;
      this.$copyText(url).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(flag) {
      let type = "android";
      if (this.os.isPhone) {
        type = "ios";
      } else {
        type = "android";
      }
      if (flag) {
        await this.showInstallTutorial(type);
      }
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toOfficial() {
      window.open("https://t.me/wylljlq");
    },
    toBusiness() {
      window.open("https://t.me/ShangWuhXiao2Ge");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobilePage {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  // padding: 18px 16px 0;

  .headerBox {
    height: 80px;
    width: 100%;
    background: url("./../../../assets/images/mobile1/headerBox.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 18px;
  }
  .content {
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .vantTabs {
    height: 100%;
    ::v-deep .van-tabs__wrap {
      border-radius: 8px;
      height: 36px;
      // border: 1px solid #0165fe;
      background: #222333 !important;
      // margin-top: 12px;
      .van-tab {
        height: 30px;
        border-radius: 6px;
        font-size: 10px;
      }
      .van-tab--active {
        background: #ff8300;
        color: #fff;
      }
      .van-tabs__nav {
        // padding: 0 3.5px;
        background: #222333 !important;
        align-items: center;
        justify-content: space-between;
      }
      .van-tabs__line {
        display: none;
      }
    }
    ::v-deep .van-tabs__content {
      height: 100%;
      .van-tabs__track {
        height: 100%;
        .van-tab__pane-wrapper {
          height: 100%;
          .van-tab__pane {
            height: 100%;
          }
        }
      }
    }
    .main {
      height: calc(100% - 200px);
      padding: 12px 0 30px;
      overflow-y: auto;
      .content1 {
        height: 822px;
        width: 355px;
        background: url("./../../../assets/images/mobile1/content1.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content2 {
        height: 1720px;
        width: 355px;
        background: url("./../../../assets/images/mobile1/content2.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content3 {
        height: 683px;
        width: 355px;
        background: url("./../../../assets/images/mobile1/content3.webp")
          no-repeat;
        background-size: 100% 100%;
      }
      .content4 {
        height: 822px;
        width: 355px;
        background: url("./../../../assets/images/mobile1/content4.webp")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .floatingBox {
    // height: 210px;
    // width: 55px;
    position: fixed;
    right: 10px;
    bottom: 110px;
    // background: url("./../../../assets/images/mobile1/floatingBox.png")
    //   no-repeat;
    background-size: 100% 100%;
    .btn1 {
      height: 40px;
      width: 40px;
      background: url("./../../../assets/images/mobile1/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-bottom: 12px;
    }
    .btn2 {
      height: 40px;
      width: 40px;
      background: url("./../../../assets/images/mobile1/officialWebsite.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .btn3 {
      height: 40px;
      width: 40px;
      position: absolute;
      left: 6px;
      bottom: 30px;
    }
  }
  .bottomBox {
    // height: 95px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px 10px 18px;
    box-sizing: border-box;
    // background: #000;
    .bottomImg {
      width: 100%;
      height: 72px;
      background: url("./../../../assets/images/mobile1/bottomBg.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}
.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;
  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }
  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }
  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }
  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
